<template>
	
	<div>
		

			<div style="text-align:right;">
					<a class="btn p-0" href="https://www.amaco.co.ke/"> <i class="bi bi-arrow-left color-1"></i> Back to main website</a>
				</div>

				<div class="row">

					<div class="col-md-4 col-3" style="padding-bottom: 15px; border-right: solid 1px #ddd;">
						<div style="text-align: right;">
					<img src="/assets/images/logo.png" class="logo">
				</div>
					</div>


					<div class="col-md-8 col-9 mt-1">
						<div style="text-align: left;">
					<h5 class="mt-2">Personal Accident Cover</h5>
					<h6 class="color-1">Covering what matters most</h6>
				</div>
					</div>


					
				</div>

			<div style="background-image: url('/assets/images/mechanic-repairing-bicycle (1).jpg'); background-size: cover; text-align: center; padding-top: 150px; padding-bottom: 100px; border-radius: 15px;">
				

			</div>



	</div>

</template>