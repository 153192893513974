import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import Checkout from '../components/Checkout'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },


   {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
