<template>


<section style="padding-top: 30px; padding-bottom: 100px;">
	
	<div class="container">

		<div class="row">
			<div class="col-md-3"></div>

			<div class="col-md-6">

			<Top />


			<div class="mt-3">
				<label>Choose option</label>
				<select class="form-select mt-1" v-model="option" @change="get_amount()">
					<option>Option A</option>
					<option>Option B</option>
					<option>Option C</option>
					<option>Option D</option>
				</select>
			</div>




			<div class="mt-3" v-if="option=='Option A'">
				<h4>Benefits : {{option}} </h4>

				<table class="table-custom">
					<thead>
						<tr> <th>Item</th> <th>Amount</th> </tr>
					</thead>

					<tbody>
					<tr> <td>Death</td> <td> Ksh.250,000 </td> </tr>
					<tr> <td>Permanent total disability</td> <td> Ksh.250,000 </td> </tr>
					<tr> <td>Temporary total disability</td> <td> Ksh.1,500 </td> </tr>
					<tr> <td>Medical expenses</td> <td> Ksh.50,000 </td> </tr>
					<tr> <td>Funeral expenses</td> <td> Ksh.10,000 </td> </tr>
					<tr> <td>Artificial appliances</td> <td> Ksh.10,000 </td> </tr>

					<tr> <td><b>Annual premium per person <br /> including levies and stamp duty</b></td> <td> <b>Ksh.{{amount}}</b> </td> </tr>



					</tbody>

				</table>

			</div>






				<div class="mt-3" v-if="option=='Option B'">
				<h4>Benefits : {{option}} </h4>

				<table class="table-custom">
					<thead>
						<tr> <th>Item</th> <th>Amount</th> </tr>
					</thead>

					<tbody>
					<tr> <td>Death</td> <td> Ksh.500,000 </td> </tr>
					<tr> <td>Permanent total disability</td> <td> Ksh.500,000 </td> </tr>
					<tr> <td>Temporary total disability</td> <td> Ksh.5,000 </td> </tr>
					<tr> <td>Medical expenses</td> <td> Ksh.70,000 </td> </tr>
					<tr> <td>Funeral expenses</td> <td> Ksh.50,000 </td> </tr>
					<tr> <td>Artificial appliances</td> <td> Ksh.12,000 </td> </tr>

					<tr> <td><b>Annual premium per person <br /> including levies and stamp duty</b></td> <td> <b>Ksh.{{amount}}</b> </td> </tr>



					</tbody>

				</table>

			</div>






				<div class="mt-3" v-if="option=='Option C'">
				<h4>Benefits : {{option}} </h4>

				<table class="table-custom">
					<thead>
						<tr> <th>Item</th> <th>Amount</th> </tr>
					</thead>

					<tbody>
					<tr> <td>Death</td> <td> Ksh.800,000 </td> </tr>
					<tr> <td>Permanent total disability</td> <td> Ksh.800,000 </td> </tr>
					<tr> <td>Temporary total disability</td> <td> Ksh.8,000 </td> </tr>
					<tr> <td>Medical expenses</td> <td> Ksh.100,000 </td> </tr>
					<tr> <td>Funeral expenses</td> <td> Ksh.60,000 </td> </tr>
					<tr> <td>Artificial appliances</td> <td> Ksh.15,000 </td> </tr>

					<tr> <td><b>Annual premium per person <br /> including levies and stamp duty</b></td> <td> <b>Ksh.{{amount}}</b> </td> </tr>



					</tbody>

				</table>

			</div>





				<div class="mt-3" v-if="option=='Option D'">
				<h4>Benefits : {{option}} </h4>

				<table class="table-custom">
					<thead>
						<tr> <th>Item</th> <th>Amount</th> </tr>
					</thead>

					<tbody>
					<tr> <td>Death</td> <td> Ksh.1000,000 </td> </tr>
					<tr> <td>Permanent total disability</td> <td> Ksh.1000,000 </td> </tr>
					<tr> <td>Temporary total disability</td> <td> Ksh.10,000 </td> </tr>
					<tr> <td>Medical expenses</td> <td> Ksh.150,000 </td> </tr>
					<tr> <td>Funeral expenses</td> <td> Ksh.70,000 </td> </tr>
					<tr> <td>Artificial appliances</td> <td> Ksh.20,000 </td> </tr>

					<tr> <td><b>Annual premium per person <br /> including levies and stamp duty</b></td> <td> <b>Ksh.{{amount}}</b> </td> </tr>



					</tbody>

				</table>

			</div>














			<div class="mt-3">
				<button @click="go_next()" class="btn btn-1">Proceed <i class="bi bi-plus"></i> </button>
			</div>

				
			</div>
			
		</div>
		
	</div>

</section>

</template>



<script>
	import Top from './Top'
	import Swal from 'sweetalert2'

	
	export default{
		name : 'home',
		components : {Top},
		data(){
			return{
				option : '',
				amount : 0
			}
		},

		methods : {

			go_next(){

				if(!this.option){
					Swal.fire({
				  title: "Error",
				  text: "Please choose an option",
				  icon: "error"
				})
					return
				}

				this.$router.push('/checkout')

			},

		

			get_amount(){
				if(this.option=='Option A'){
					this.amount = 1282
				}

				if(this.option=='Option B'){
					this.amount = 1773
				}

				if(this.option=='Option C'){
					this.amount = 2682
				}

				if(this.option=='Option D'){
					this.amount = 3591
				}

				this.$store.state.amount = this.amount
				localStorage.setItem('amount',this.amount)

				this.$store.state.package = this.option
				localStorage.setItem('package',this.option)

			}

		}
	}

</script>



