import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://www.productsend.amaco.co.ke/',
    amount : localStorage.getItem('amount'),
    package : localStorage.getItem('package')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
