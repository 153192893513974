<template>



<div :class=" { 'blur' : show_modal } ">



<section style="padding-top: 30px; padding-bottom: 100px;">
	
	<div class="container">

		<div class="row">
			<div class="col-md-3"></div>

			<div class="col-md-6">

			<Top />


			<div class="mt-3">

				<h6>Check out</h6>

				<div>
					
					<div class="row">

						<div class="col-md-12 mt-2">
							<label>Full name</label>
							<input type="text" placeholder="Enter full name" class="form-control" v-model="fullname">
						</div>


						<div class="col-md-6 mt-2">
							<label>Nationality</label>
							<input type="text" placeholder="e.g. Kenyan" class="form-control" v-model="nationality">
						</div>

						<div class="col-md-6 mt-2">
							<label>National ID/Passport No.</label>
							<input type="text" placeholder="e.g. 12345678" class="form-control" v-model="national_id">
						</div>

						<div class="col-md-6 mt-2">
							<label>Date of birth</label>
							<input type="date" placeholder="Enter date of birth" class="form-control" v-model="dob">
						</div>


						<div class="col-md-6 mt-2">
							<label>Gender</label>
							<select class="form-select" v-model="gender">
								<option>Male</option>
								<option>Female</option>
							</select>
						</div>


						<div class="col-md-6 mt-2">
							<label>Physical address</label>
							<input type="text" placeholder="Enter your physical address" class="form-control" v-model="address">
						</div>


						<div class="col-md-6 mt-2">
							<label>PIN No.</label>
							<input type="text" placeholder="Enter your PIN number" class="form-control" v-model="pin">
						</div>


						<div class="col-md-6 mt-2">
							<label>Phone number</label>
							<input type="phone" placeholder="Enter phone number" class="form-control" v-model="phone">
						</div>


						<div class="col-md-6 mt-2">
							<label>Email</label>
							<input type="email" placeholder="Enter email address" class="form-control" v-model="email">
						</div>


						<div class="col-md-12 mt-3">
						<h6>Beneficiary</h6>
						</div>


						<div class="col-md-6 mt-2">
							<label>Full name</label>
							<input type="text" placeholder="Enter full name" class="form-control" v-model="beneficiary_fullname">
						</div>

						<div class="col-md-6 mt-2">
							<label>National ID</label>
							<input type="text" placeholder="Enter national ID" class="form-control" v-model="beneficiary_id">
						</div>

						<div class="col-md-6 mt-2">
							<label>Phone number</label>
							<input type="text" placeholder="Enter phone number" class="form-control" v-model="beneficiary_phone">
						</div>


						<div class="col-md-6 mt-2">
							<label>Relationship</label>
							<input type="text" placeholder="Enter your relationship" class="form-control" v-model="relationship">
						</div>





						
					</div>

				</div>
				
			</div>


	
			<div class="mt-3">
				<button @click="checkout()"  class="btn btn-1"> Check out <i class="bi bi-plus"></i> </button>
			</div>

				
			</div>
			
		</div>
		
	</div>

</section>
	

</div>





<div v-if="show_modal" style="position: fixed; right: 0; top: 0; width: 100%; padding-top: 200px;">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">

			<div style="background-color: #fff; border-radius: 15px; padding: 10px; text-align: center; ">
				
				<h1 class="color-1"> <i class="bi bi-info-circle"></i> </h1>
				<h6>You are almost there!</h6>

				<div>
					
					<button @click="pay_now()" class="btn btn-1" style="margin-right: 10px;">Pay Now</button>
					<button :disabled="disabled" @click="pay_later()" class="btn btn-1"> {{text}} </button>

				</div>


				<div class="mt-3">
					<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window </button>
				</div>

			</div>
			
		</div>

		<div class="col-md-4"></div>
		
	</div>

</div>





</template>



<script>
	import Top from './Top'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Top},
		data(){
			return{
				fullname : '',
				nationality : '',
				national_id : '',
				gender : '',
				dob : '',
				address : '',
				pin : '',
				phone : '',
				email : '',
				beneficiary_fullname : '',
				beneficiary_phone : '',
				beneficiary_id : '',
				relationship : '',
				text : 'Pay Later',
				disabled : false,
				show_modal : false
			}
		},

		methods : {

			pay_now(){

				Swal.fire({
				  title: "Please note",
				  text: "This module is coming soon...",
				  icon: "info"
				})

			},

			async pay_later(){
				this.disabled = true
				this.text = "Please await..."
				const res = await axios.post(this.$store.state.url+'api/pay-later',{

				package : this.$store.state.package,
				amount : this.$store.state.amount,
				fullname : this.fullname,
				national_id : this.national_id,
				nationality : this.nationality,
				gender : this.gender,
				dob : this.dob,
				address : this.address,
				pin : this.pin,
				phone : this.phone,
				email : this.email,
				beneficiary_fullname : this.beneficiary_fullname,
				beneficiary_phone : this.beneficiary_phone,
				beneficiary_id : this.beneficiary_id,
				relationship : this.relationship,
				payment_method : 'Pay Later'

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.disabled = false
				this.text = "Pay Later"
				this.show_modal = false

				Swal.fire({
				  title: "Success",
				  text: "Order submitted successfully",
				  icon: "success"
				})

				setTimeout(()=>{
					localStorage.clear()
					this.$router.push('/')

				},3000)

			},


			checkout(){




				if(!this.fullname){
					Swal.fire({
				  title: "Error",
				  text: "Full name is required",
				  icon: "error"
				})
					return
				}

				if(!this.nationality){
					Swal.fire({
				  title: "Error",
				  text: "Nationality is required",
				  icon: "error"
				})
					return
				}


				if(!this.national_id){
					Swal.fire({
				  title: "Error",
				  text: "National ID/Passport ID is required",
				  icon: "error"
				})
					return
				}


				if(!this.gender){
					Swal.fire({
				  title: "Error",
				  text: "Gender is required",
				  icon: "error"
				})
					return
				}


				if(!this.dob){
					Swal.fire({
				  title: "Error",
				  text: "Date of birth is required",
				  icon: "error"
				})
					return
				}

				if(!this.address){
					Swal.fire({
				  title: "Error",
				  text: "Physical address is required",
				  icon: "error"
				})
					return
				}


				if(!this.phone){
					Swal.fire({
				  title: "Error",
				  text: "Phone number  is required",
				  icon: "error"
				})
					return
				}



				if(!this.email){
					Swal.fire({
				  title: "Error",
				  text: "Email address is required",
				  icon: "error"
				})
					return
				}



				if(!this.beneficiary_fullname){
					Swal.fire({
				  title: "Error",
				  text: "Beneficiary full name is required",
				  icon: "error"
				})
					return
				}


				if(!this.beneficiary_phone){
					Swal.fire({
				  title: "Error",
				  text: "Beneficiary phone is required",
				  icon: "error"
				})
					return
				}


				if(!this.beneficiary_id){
					Swal.fire({
				  title: "Error",
				  text: "Beneficiary national ID is required",
				  icon: "error"
				})
					return
				}


				if(!this.relationship){
					Swal.fire({
				  title: "Error",
				  text: "Relationship is required",
				  icon: "error"
				})
					return
				}


				this.show_modal = true





			}

		}
	}

</script>



